import React from "react";
import { graphql } from 'gatsby';
import TemplateWrapper from "../../components/template-wrapper";
import ContactForm from "../../components/contact-form";
//import phone_text from "../../images/phone_text.png";

export default function cstContact ({ data }) {
  const headerImages = data.headerImages;

  return (
    <div className="palette--cst-site" style={{ textAlign: "center" }}> 
    <TemplateWrapper headerImages = {headerImages} category={""} >
      <div style={{padding: "1.0rem", position: "relative"}}>
        <span>
          <em style={{fontWeight: "700"}}>CyberSym Technologies</em><br/>
          PO Box 6535<br/>
          North Logan, UT 84341-6535<br/>
          USA
        </span>
        {/* <img src={phone_text} alt="" 
          style={{
            zIndex: "1",
            position: "absolute",
            right: "1.0rem",
            top: "1.1rem"
          }}
        />  */}
      </div>
      <ContactForm />   
    </TemplateWrapper>
    </div>
  );
}

export const pageQuery = graphql`
  query cstContactQuery {
    headerImages: allImageSharp(
      filter: {
        fluid: { originalName: { regex: "/cst/" } }
      }  
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 1600, maxHeight: 226) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }  
`;

