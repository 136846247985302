import React from 'react';
import axios from 'axios';
import "../styles/blogcontent.less"

export default class ContactForm extends React.Component {
    constructor( props ) {
        super(props);

        this.state = {
            origin: "cybersym.com",
            name: "",
            lastName: "",
            email: "",
            subject: "",
            body: "",
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState( {
            [name]: value,
        } )

        if (this.state.email.length > 2 && this.state.name.length > 0 && this.state.body.length > 0)
          document.getElementById("abBtn").disabled = false;
        else  
          document.getElementById("abBtn").disabled = true;
    }

    handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        // const submitUrl = "blah, blah, ...";
        const name = event.target.name.value;
        // const lastName = event.target.lastName.value;
        // const email = event.target.email;
        // const subject = event.target.subject.value;
        // const body = event.target.body.value;

        // var nameRegEx = /[A-Za-z]{1][A-Za-z]/;
        // if (! nameRegEx.test(name))
        //     alert('name');

        // var emailRegEx = /[A-Za-z]{1][A-Za-z]/;
        // if (email.length < 6 || ! email.contains('@'))
        //     alert('email');

        // if (subject.length < 1)
        //     alert('subject');

        // if (body.length < 1)
        //     alert('body');

        const options = {
           url:  "https://sbqi0dzui9.execute-api.us-west-2.amazonaws.com/production/contact",
           method: 'POST',
           headers: { 'content-type': "application/json" },
           data:  JSON.stringify(this.state), 
        }
         
        axios( options );
        console.log(`Thanks for your message ${name}!`);
        this.clear(event);
      }
    }

    clear = (event) => {
        event.target.name.value="";
        event.target.email.value="";
        event.target.subject.value="";
        event.target.body.value="";
        event.target.lastName.value="";
        event.target.submit.disabled=true;
    }

    render() {
        return (
        <form id="contact-form" className="contact-form" onSubmit={this.handleSubmit} >
            <h2><center>Contact Us by Email:</center></h2>
            <label id="ab1" >Your Name:
                <input type="text" name="name" value={this.state.name} onChange={this.handleInputChange} required maxLength="128" placeholder="Enter your name here" />
            </label>
            <label id="ab2" >Last Name:
                <input type="text"name="lastName" value={this.state.subject} onChange={this.handleInputChange} maxLength="128" placeholder="Enter your last name here" />
            </label>
            <label id="ab3" >Your Email:
                <input type="email" name="email" value={this.state.email} onChange={this.handleInputChange} required maxLength="256" placeholder="Enter your email address here" />
            </label>
            <label id="ab4" >Subject:
                <input type="text" name="subject" value={this.state.subject} onChange={this.handleInputChange} maxLength="128" placeholder="Enter your subject here" />
            </label>
            <label id="ab5" >Your Message:
                <textarea type="textarea" name="body" value={this.state.body} onChange={this.handleInputChange} required maxLength="2048" placeholder="Enter your message here" />
            </label>
            <button id="abBtn" disabled type="submit" name="submit">Send</button>
        </form> 
        )   
    }
}