
import React from "react";
// import Link from "gatsby-link";
import { StaticImage } from "gatsby-plugin-image";
// import HeaderSlider from "./header-slider.js";
import TopMenu from "./top-menu.js";

import "../styles/flex.less";

const Header = (data) => {
  return (
  <header 
    className="flex-header"
    style={{
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    }}
  >
    <div style={{zIndes: "0", position: "relative"}}>
      {/* <HeaderSlider headerImages={data.headerImages} /> */}
      <StaticImage src="../images/cst_logo_graph_gatsby.png" alt="CyberSym Logo" placeholder="blurred" layout="fullWidth" aspectRatio={7.079646} formats={["auto", "png"]}/>
      {/* <Link to={'/'80
      </Link> */}
      <div style={{
        zIndex: "1",
        color: "#001155", 
        fontFamily: "Roboto Slab", 
        fontSize: "45px", 
        fontWeight: "700", 
        position: "absolute", 
        // top: "82px", 
        left: "0", 
        textAlign: "center", 
        width: "100%",
        top: "42%"}}
      >
        <em style={{transform: "skewX(20degree)" }} >CyberSym Technologies </em>
      </div>  
      <div style={{
          zIndex: "2",
          position: "absolute",
          left: "0.5rem",
          top: "0.25rem"
        }} 
      >  
        <TopMenu category={""} /> 
      </div>   
    </div>
  </header>
  )
}

export default Header;

